<template>
    <div class="logOutBox">
        <!-- <myAccount></myAccount> -->
        <!-- 登录框-->
        <div class="loginDialog" v-if="status == 1">
            <div class="loginTitle">Login</div>
            <div class="mantory">* Required fields</div>
            <el-form
                ref="loginForm"
                :rules="loginRules"
                :label-position="labelPosition"
                :model="loginForm"
                class="demo-ruleForm"
                size="small"
                :hide-required-asterisk="true"
                @submit.native.prevent
            >
                <el-form-item label="Email address *" prop="email">
                    <el-input v-model="loginForm.email" placeholder="Your email address"></el-input>
                </el-form-item>
                <el-form-item label="Password *" prop="password">
                    <el-input type="password" v-model="loginForm.password" placeholder="Password"></el-input>
                </el-form-item>
                <div class="forgetPwd">
                    <button @click="forgetpwdFn">
                        <span>Forgot your password?</span>
                    </button>
                </div>
                <div>Consult our
                    <router-link target="_blank" :to="{path: '/privacy'}" >Privacy Policy</router-link>
                    for further information.
                </div>
                <div class="buttonsRow" @click="confirmLogin('loginForm')">
                    <button class="submit loginSub" type="button">Login</button>
                </div>
            </el-form>
            <div class="registBox">
                <h2>Registration</h2>
                <p>Register now and make the most of My Account. You will be able to:</p>
                <ul>
                    <li>Receive our exclusive newsletter</li>
                    <li>Save your favorite items</li>
                    <li>Shop faster</li>
                    <li>Check your orders and returns</li>
                </ul>
                <div class="registBtn">
                    <button class="submit registSub">
                        <router-link :to="{path:'/register'}">Register</router-link>
                    </button>
                </div>
            </div>
        </div>
        <!--          忘记密码-->
        <el-drawer :visible.sync="forgetDrawer" :modal="false" :direction="forgetDir" class="forgetPwd">
            <div class="forgetLogin">
                <el-form :rules="forgetPasswordRules" :label-position="forgetPosition" :model="formLabelAligns"
                         class="demo-ruleForm" size="small" :hide-required-asterisk="true">
                    <p>Enter the email address you use for My Account and you will receive an email containing a link to
                        change
                        your password.</p>
                    <div class="requires">* Required fields</div>
                    <el-form-item label="Email address *" prop="region" style="color:#820000">
                        <el-input v-model="formLabelAligns.region" placeholder="Your email address"></el-input>
                    </el-form-item>
                    <div class="btn">
                        <button class="send">Send</button>
                    </div>
                </el-form>
            </div>
            <!--  回到登录-->
        </el-drawer>
    </div>
</template>
<script>
import {login} from "@/api/api";

export default {
    name: "login",
    components: {},
    data() {
        return {
            currentSelectedCategoryId: null,
            ipt: false,
            showContent: false,
            forgetDrawer: false,
            registDrawer: false,
            direction: "rtl",
            forgetDir: "btt",
            regDirections: "rtl",
            labelPosition: "top",
            forgetPosition: "top",
            status: 1,//默认是登录弹窗
            isLogin: 11,//默认是修改密码窗口
            options: [{
                value: "选项1",
                label: "黄金糕"
            }, {
                value: "选项2",
                label: "双皮奶"
            }, {
                value: "选项3",
                label: "蚵仔煎"
            }, {
                value: "选项4",
                label: "龙须面"
            }, {
                value: "选项5",
                label: "北京烤鸭"
            }],
            value: "",
            // 登陆表单
            formLabelAlign: {
                name: "",
                region: ""
            },
            formLabelAligns: {
                region: ""
            },
            // 返回登录
            backForm: {
                address: "",
                password: ""
            },
            loginForm: {
                email: "",
                password: ""
            },
            checkList: [
                "", ""
            ],
            forgetPasswordRules: {
                firstName: [
                    {required: true, message: "The name field is required", trigger: "blur"}
                ],
                lastName: [
                    {required: true, message: "The last name field is required", trigger: "blur"}
                ],
                address: [
                    {required: true, message: "The email address field is required", trigger: "blur"}
                ],
                password: [
                    {required: true, message: "The password field is required", trigger: "blur"}
                ],
                confPass: [
                    {required: true, message: "Re-enter your password", trigger: "blur"}

                ]
            },
            loginRules: {
                email: [
                    {required: true, message: "The email address field is required", trigger: "blur"}
                ],
                password: [
                    {required: true, message: "The password field is required", trigger: "blur"}
                ]
            },
            // 忘记密码
            regRules: {
                name: [
                    {required: true, message: "The email address field is required", trigger: "blur"}
                ],
                region: [
                    {required: true, message: "The password field is required", trigger: "blur"}
                ]
            },
            // 返回登录
            backRule: {
                address: [
                    {required: true, message: "The email address field is required", trigger: "blur"}
                ],
                password: [
                    {required: true, message: "The password field is required", trigger: "blur"}
                ]
            }
        };
    },
    methods: {
        forgetpwdFn() {
            this.forgetDrawer = true;
        },
        backForget() {

        },
        confirmLogin(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    const {email, password} = this.loginForm;
                    const res = await login({email, password});
                    const {code, data, message} = res;
                    if (code !== 0) {
                        this.$message({
                            showClose: true,
                            message: message,
                            type: "error"
                        });
                    } else {
                        const {accessToken} = data;
                        this.accessToken = accessToken;
                        sessionStorage.setItem("accessToken", accessToken);

                        this.$parent.fetchUserInfo();

                        this.$message({
                            showClose: true,
                            message: "Login successful",
                            type: "success"
                        });

                        if (this.$route.path === "/login") {
                            this.$router.push({path: "/overView"});
                        }
                    }
                    console.log(res);
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
a {
    text-decoration: none;
    color: inherit;
}

button {
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 0 none;
    border-radius: 0;
    letter-spacing: inherit;
    padding: 0;
    color: inherit;

    &:focus {
        outline: 0;
    }
}

h1, h2, h3, h4 {
    font-weight: 400;
    font-size: 1em;
}

nav {
    display: none;
}

::v-deep .el-checkbox__label {
    color: #212121;
    font-size: .8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    line-height: 30px;
    word-break: break-all;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #212121;
    font-size: .8125rem;
    font-weight: 400;
    font-style: normal;
    white-space: normal;
    word-break: break-all;
}

::v-deep .el-checkbox__inner {
    border-color: #212121;
    width: .5rem;
    height: .5rem;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #212121;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #212121;
    border: none;
}

::v-deep .el-checkbox__input {
    vertical-align: top;
    line-height: 2;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    display: none;
}

::v-deep .el-checkbox {
    margin-bottom: 1rem;
    padding: 0 1rem;
}

::v-deep .el-input__inner {
    border: none;
    border-radius: 0;
    padding: 0;
    border-bottom: 1px solid #d8d7d5;
}

::v-deep .el-form-item__label {
    padding: 0;
    line-height: .1rem;
}

::v-deep .el-select {
    width: 100%;
}

::v-deep .el-tabs__nav-wrap::after {
    display: none;
}

::v-deep .el-tabs__nav-scroll {
    display: flex;
}

::v-deep .el-tabs__header {
    margin: 0;
    padding: 1rem 0;
}

::v-deep .el-tabs__item {
    height: .8rem;
    line-height: 0;
    font-size: inherit;
}

::v-deep .el-tabs__item:hover {
    color: inherit;
}

::v-deep .el-tabs__active-bar {
    background: #212121;
    height: 1px;
}

::v-deep .el-tabs__item {
    font-weight: 400;
}

::v-deep .el-tabs__item.is-active {
    color: #212121;
}

.logOutBox {
    .loginDialog {
        width: 27.5rem;
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;

        a {
            //border-bottom: 1px solid currentColor;
            padding-bottom: .125rem;
        }

        .loginTitle {
            margin-bottom: 1rem;
        }

        .forgetPwd {
            line-height: 1.1875rem;
            margin-bottom: 1rem;

            button {
                border-bottom: 1px solid transparent;
            }
        }

        .forgetPwd button:hover {
            cursor: pointer;
            border-bottom: 1px solid currentColor;
        }

        .submit {
            background-color: #212121;
            border-width: 0;
            color: #fff;
            display: block;
            padding: 0.525rem 0.3125rem 0.575rem;
            text-align: center;
            width: 65%;
            margin: 2rem 0 3rem 0;
        }

        .loginSub:hover {
            cursor: pointer;
            opacity: .8;
        }

        .registSub:hover {
            cursor: pointer;
            opacity: .8;
        }

        .registerSub:hover {
            cursor: pointer;
            opacity: .8;
        }

        ::v-deep .el-form-item__label {
            padding: 0;
            line-height: 0;
        }

        ::v-deep .el-form-item {
            margin-top: 1rem;
            padding-bottom: 1rem;
        }

    }

    .registBox {
        padding-top: 1rem;

        h2 {
            margin-bottom: 2rem;
            font-size: 1em;
            font-weight: 400;
        }

        p {
            font-size: .8125rem;
        }

        ul {
            flex-direction: column;

            li {
                margin-left: 0;
                flex-direction: row;
                font-size: .8125rem;
                line-height: 1.1875rem;
                letter-spacing: .01562rem;
            }

            li:before {
                content: "·";
                margin-right: 3px;
            }
        }

        .registBtn {
            margin-top: 2rem;
        }
    }

    .forgetLogin {
        width: 30rem;
        padding: 3.9375rem calc(4% + .8125rem);
        margin: 0 auto;

        p {
            line-height: 1.1875rem;
        }

        .btn {
            display: flex;
            align-items: flex-start;
            flex-direction: row;
            justify-content: center;

            button {
                max-width: 18.75rem;
                padding: .3125rem .3125rem .375rem;
                width: 100%;
                margin-left: .3125rem;
                margin-right: .3125rem;
                margin-top: 1rem;
            }

            .send {
                color: #fff;
                background: #212121;
            }

            .send:hover {
                cursor: pointer;
                opacity: .8;
            }
        }

        ::v-deep .el-form-item__label {
            padding: 0;
            line-height: 0;
        }

        ::v-deep .el-form-item {
            margin-top: 2rem;
            padding-bottom: 2rem;
        }

    }

    .forgetPwd {
        margin: .25rem 0 1.5rem 0;

        ::v-deep .btt {
            min-height: 82vh;
        }

        &:hover {
            span {
                cursor: pointer;
                border-bottom: 1px solid;
                padding-bottom: .125rem;
            }
        }

    }
}

@media (max-width: 1280px) {
    .logOutBox {
        margin-top: 3.125rem;

        .loginDialog {
            padding-top: 2rem;
            width: auto;
        }

        .loginTitle, .mantory, form, .registBox {
            margin: 0 1.1875rem;
        }

        .submit {
            width: 100% !important;
            padding: 1rem 3.125rem .9375rem !important;
        }
    }
    ::v-deep .el-drawer__wrapper {
        margin-top: 3.125rem !important;
        margin-bottom: 0 !important;
    }
    ::v-deep .el-drawer {
        height: 100% !important;
    }
    ::v-deep .forgetLogin {
        width: auto !important;
        padding: 0 !important;
        margin-top: 3.9375rem !important;
    }
    .send {
        padding: 1rem 3.125rem .9375rem !important;
        max-width: none !important;
        margin: 0 !important;
    }
}
</style>
